.container-project {
  margin-top: 8rem;
}

.project-header {
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
}

.project-box {
  border-radius: 10px;
  max-width: 60%;
  padding: 1.5rem;
  background-color: var(--gray-color);
}

.text-box {
  margin-top: 0.3rem;
}

.project-line {
  position: relative;
  left: 10px;
  bottom: -40px;
}

/* Details */

.project-details {
  margin: 3rem 0;
  display: flex;
  flex-wrap: wrap;
}

.details-left {
  margin: 0 2rem;
}

.text-figma {
  cursor: pointer;
  color: var(--white-color);
  display: inline-flex;
  align-items: center;
}

.text-figma:hover {
  transition: 350ms;
  background: linear-gradient(
    90deg,
    #f24e1e 2.73%,
    #a259ff 37.68%,
    #13c5c6 71.63%,
    #0acf83 100%
  );
  background-size: 400vh;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  animation: text-move infinite 3s alternate;
}

.icon-figma {
  width: 10px;
  margin-right: 5px;
  margin-left: 10px;
}

.details-stack {
  margin: 3rem 1rem;
}

@media (max-width: 425px) {
  .project-details {
    justify-content: center;
  }
  .project-header {
    justify-content: center;
  }

  .project-box {
    max-width: 100%;
  }

  .details-left {
    margin: 0;
  }
}

@keyframes text-move {
  0% {
    background-position: left;
  }

  100% {
    background-position: right;
  }
}
